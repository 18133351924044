<template>
  <div>
    <div class="button">
      <el-input
        v-model="q"
        placeholder="请输入关键字"
        @keyup.enter.native="submit"
        prefix-icon="el-icon-q"
      ></el-input>
      <el-button
        type="primary"
        size="medium"
        @click="submit"
        :loading="loginLoading"
        >搜索</el-button
      >
    </div>
    <div class="bigBox">
      <div v-for="(item, index) in data" :key="index" class="item">
        <div :class="index % 2 == 1 ? 'active' : ''">{{ item }}</div>
      </div>
      <p style="text-align: center" v-if="data.length == 0">
        当前没有获取到数据
      </p>
    </div>
    <div class="tips" style="margin-left: 10px">微信搜索下方小程序：</div>
    <div class="tips">《水印相机打卡》</div>
    <div class="tips">《时间地点 | 水印相机打卡》</div>
    <div class="tips">《考勤相机 | 水印相机打卡》</div>
    <div class="tips">《考勤 | 水印相机打卡》</div>
    <div style="text-align: center" id="hitokoto" @click="changeWord">
      {{ woInfo.hitokoto }}
    </div>
    <div
      style="text-align: right; margin-right: 45px; margin-bottom: 45px"
      id="hitokoto"
      @click="changeWord"
    >
      -----{{ woInfo.from }}
    </div>
    <div class="cop">Copyright © 2020 By LCL</div>
  </div>
</template>

<script>
import * as api from "../api/api";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      data: [],
      q: "",
      loginLoading: false,
      woInfo: {}, //文字
    };
  },
  created() {
    this.changeWord();
  },
  methods: {
    changeWord() {
      api.get("https://v1.hitokoto.cn").then((res) => {
        this.woInfo = res;
      });
    },
    submit() {
      this.loginLoading = true;
      //获取token
      if (this.q) {
        this.loginLoading = false;
        api
          .post("api/get/learn/question", {
            q: encodeURI(this.q),
          })
          .then((res) => {
            console.log(res);
            this.loginLoading = false;
            let newArr = JSON.parse(res.data).splice(1); //截取
            this.data = newArr.filter((item) => {
              return item != this.q;
            });
            this.q = "";
            this.data.pop();
            this.data.pop();
          });
      } else {
        this.$message.error("请填写关键字~");
        this.loginLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.button {
  display: flex;
  margin-top: 30px;
}
.item {
  font-size: 20px;
  margin-bottom: 5px;
}
.tips {
  text-align: center;
  color: red;
}
.active {
  color: red;
}
.bigBox {
  margin-bottom: 30px;
  min-height: 45vh;
}
.cop {
  font-size: 14px;
  color: gray;
  text-align: center;
  bottom: 5px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
</style>
